import { Injectable } from '@angular/core';
import { catchError, from, of, ReplaySubject, switchMap, timeout } from 'rxjs';
import { CognitoUserPool } from './cognito-user-pool.interface';

@Injectable({
  providedIn: 'root',
})
export class CognitoUserPoolConfigService {
  public fetchStart: number;

  public defaultProdConfig: CognitoUserPool = {
    user_pool_id: 'us-east-1_HzpqCcEnY',
    client_id: '45to71h4ai78nd1j1roi2elche',
    oauth_domain: 'callrail.auth.us-east-1.amazoncognito.com',
  };

  /* eslint-disable-next-line local-rules/no-ng-location */
  public userPoolUrl = `${location.origin}/anubis/v1/user-pool`;

  public userPoolConfig$ = new ReplaySubject<CognitoUserPool>(1);

  public useFetchedUserPoolIds(timeoutIn = 1000): void {
    this.fetchStart = Date.now();
    from(this.fetchUserPoolConfig())
      .pipe(
        // Only wait 1 sec for a response before erroring and continuing with Production values when in QA Environments.
        timeout(timeoutIn),
        switchMap((response: Response) => this.userPoolFromResponse(response)),
        catchError((error) => {
          this.useDefaultProdUserPoolIds();
          return of(error);
        })
      )
      .subscribe((userPoolConfig: CognitoUserPool) => {
        this.setUserPoolIds(userPoolConfig);
      });
  }

  /* setDefaultUserPoolIds - Defaults to Production Userpool*/
  public useDefaultProdUserPoolIds(): void {
    this.setUserPoolIds(this.defaultProdConfig);
  }

  public setUserPoolIds(data: CognitoUserPool): void {
    this.userPoolConfig$.next(data);
    this.userPoolConfig$.complete();
  }

  public fetchUserPoolConfig(): Promise<Response> {
    return fetch(this.userPoolUrl, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  }

  private userPoolFromResponse(response: Response): Promise<CognitoUserPool> {
    return response.ok
      ? (response.json() as Promise<CognitoUserPool>)
      : Promise.reject('response not.ok');
  }
}
