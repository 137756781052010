import { enableProdMode, NgModule } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { configureUserPool } from '@callrail/looky/anubis/login/shared';
import { AppModule } from './app/app.module';
import { env } from './environments/environment';

if (env.production) {
  enableProdMode();
}

configureUserPool()
  .then(() => platformBrowserDynamic().bootstrapModule<NgModule>(AppModule))
  // eslint-disable-next-line no-console
  .catch(console.error);
