import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { Locale } from '../../interfaces/locale';
import { AgencyEvent } from '../../models/agency-event.model';
import { AgencyModel } from '../../models/agency.model';
import { Country } from '../../models/country.model';

@Injectable({
  providedIn: 'root',
})
export class AgencyService {
  public agencyEvents: Subject<AgencyEvent>;

  private localesCache: { [key: number]: Locale[] } = {};
  private countriesCache: { [key: number]: Country[] } = {};
  private timezoneCache: { [key: number]: any } = {};

  constructor(private http: HttpClient) {
    this.agencyEvents = new Subject<AgencyEvent>();
  }

  /**
   * Updates the context with the agency result value
   * @param id
   * @param params
   */
  public get(id: string, params = {}): Observable<AgencyModel> {
    const url = `${AgencyModel.getRoute(id)}.json`;
    return this.getUrl(url, params);
  }

  public getWithPhonyConfig(id: string): Observable<AgencyModel> {
    const url = `${AgencyModel.getRoute(id)}.json`;
    const params: any = { with_phony_config: true };
    return this.http
      .get<AgencyModel>(url, { params })
      .pipe(map((agencyData) => new AgencyModel(agencyData)));
  }

  public getUpdatedContext(id: string): Observable<AgencyModel> {
    const url = `${AgencyModel.getRoute(id)}/reload_context.json`;
    return this.getUrl(url);
  }

  public put(updatedModel: AgencyModel): Observable<any> {
    const url = `${updatedModel.route}.json`;
    return this.http.put<AgencyModel>(url, updatedModel);
  }

  public putAsForm(agency: AgencyModel): Observable<any> {
    const url = `${AgencyModel.getRoute(agency.id)}.json`;
    const formData = new FormData();
    for (const attr in agency) {
      if (agency.hasOwnProperty(attr)) {
        formData.append(`agency[${attr}]`, agency[attr]);
      }
    }
    return this.http.put(url, formData);
  }

  public patchById(id: string, patchData: object): Observable<any> {
    const url = `${AgencyModel.getRoute(id)}.json`;
    return this.http.patch<AgencyModel>(url, { agency: patchData });
  }

  public patchModel(original: AgencyModel, changeSet: any): Observable<any> {
    if (Object.keys(changeSet).length === 0) {
      return Observable.create((obs) => {
        obs.onError('Empty patch');
      });
    }
    return this.patchById(original.id, changeSet);
  }

  public locales(agencyId: string): Promise<any> {
    if (this.localesCache[agencyId]) {
      return of(this.localesCache[agencyId]).toPromise();
    }
    const agency = new AgencyModel({ id: agencyId, name: '' });
    return this.http
      .get(`${agency.route}/locales`)
      .pipe(tap((locales) => (this.localesCache[agencyId] = locales)))
      .toPromise();
  }

  public timezones(agencyId: string): Observable<any> {
    if (this.timezoneCache[agencyId]) {
      return of(this.timezoneCache[agencyId]);
    }
    const url = `${AgencyModel.getRoute(agencyId)}`;
    return this.http
      .get(`${url}/companies/time_zones.json`)
      .pipe(tap((timezones) => (this.timezoneCache[agencyId] = timezones)));
  }

  public countries(agencyId: string): Observable<Country[]> {
    if (this.countriesCache[agencyId]) {
      return of(this.countriesCache[agencyId]);
    }
    const url = `${AgencyModel.getRoute(agencyId)}`;
    return this.http.get<Country[]>(`${url}/countries`).pipe(
      map((countries) => {
        this.sortCountries(countries);
        this.countriesCache[agencyId] = countries;
        return countries;
      })
    );
  }

  public updateResellerAccountBasics(
    agencyId: string,
    accountBasics: {
      name: string;
      industry: string;
    }
  ): Observable<any> {
    const url = `${AgencyModel.getRoute(agencyId)}/update-basic-info.json`;
    return this.http.put(url, accountBasics);
  }

  private sortCountries(countries: Country[]): void {
    countries.sort((countryA, countryB) => {
      if (countryA.code === 1) {
        return -1;
      } else if (countryB.code === 1) {
        return 1;
      } else if (countryA.name < countryB.name) {
        return -1;
      } else if (countryA.name > countryB.name) {
        return 1;
      }
      return 0;
    });
  }

  private getUrl(url: string, params = {}): Observable<AgencyModel> {
    return this.http.get<AgencyModel>(url, { params }).pipe(
      map((agencyData) => {
        const agency = new AgencyModel(agencyData);
        this.agencyEvents.next(new AgencyEvent(agency.id, 'GET', agency));
        return agency;
      })
    );
  }
}
