/**
 * Contains the base of the path that determines which app is loaded.
 * This file's location is hard-coded in the app generator. If you need to move it,
 * please update the location of this file in the variable `APP_ROUTES_PATH`
 */
export enum AppRoutes {
  SUPERADMIN = '/sa/',
  ACCOUNT_CENTER = '/account-center/',
  ANALYTICS = '/analytics/',
  LEAD_CENTER = '/lead-center/',
  CABOOSE = '/caboose/',
  CFB = '/cfb/',
  SETTINGS = '/settings/',
  AUTHY = '/authy/',
  ANUBIS = '/authenticate/',
  TOKEN = '/token/',
  MESSENGER = '/messenger/',
  TCW = '/tcw/',
  DATA = '/data/',
  MFB = '/mfb/',
  INTEGRATE = '/integrate/',
}
