import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Alert } from '../../models/alert.model';

@Component({
  selector: 'ui-structured-alert',
  templateUrl: './structured-alert.component.html',
})
export class StructuredAlertComponent implements OnInit {
  public arraySegments: string[] | Alert['segments'];

  @Input() public segments: string | string[] | Alert['segments'];

  @Output() public close = new EventEmitter();

  public ngOnInit() {
    if (this.segments && Array.isArray(this.segments)) {
      this.arraySegments = this.segments;
    }
  }
}
