/* eslint-disable no-underscore-dangle */
import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { SelectionModel } from '@angular/cdk/collections';
import {
  DOWN_ARROW,
  END,
  ENTER,
  HOME,
  SPACE,
  UP_ARROW,
} from '@angular/cdk/keycodes';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Inject,
  InjectionToken,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Optional,
  Output,
  QueryList,
  SimpleChanges,
} from '@angular/core';
import { defer, merge, Observable, Subject } from 'rxjs';
import { filter, startWith, switchMap, take, takeUntil } from 'rxjs/operators';
import { compareFromId } from '../comparators/compare-from-id';
import { OptionSelectionChange } from '../select-option/option-selection-change';
import {
  SelectOptionComponent,
  SELECT_OPTION_PARENT_COMPONENT,
} from '../select-option/select-option.component';

export interface UiSelectComponent {
  close: () => void;
  open: () => void;
}

/**
 * Injection token used to provide the parent component to options.
 */
export const UI_SELECT_COMPONENT = new InjectionToken<UiSelectComponent>(
  'UI_SELECT_COMPONENT'
);

// a component that holds cr-option components for a list
// provides basic focus and highlight with keyboard navigation
@Component({
  selector: 'ui-select-option-list',
  template: `
    <div
      class="cr-option-list"
      (keydown)="onKeydown($event)"
      (focus)="onFocus()"
      tabindex="0">
      <ng-content></ng-content>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: SELECT_OPTION_PARENT_COMPONENT,
      useExisting: SelectOptionListComponent,
    },
  ],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class SelectOptionListComponent
  implements OnDestroy, AfterContentInit, OnChanges
{
  /**
   * Value of the select option list. This can be in any format, an array of id's, an array of objects, array of strings, etc.
   * The compareWith function determines how this list of values is compared against the list of options to determine which are selected.
   *
   * Changing the value input will update the selection and emit from the valueChange emitter, it will not emit from the onSelect.
   * The onSelect emitter is for changes from user input.
   */
  @Input()
  get value(): any {
    return this._value;
  }
  set value(val: any) {
    if (val !== this._value) {
      if (this.multiple) {
        if (Array.isArray(val)) {
          this._value = [...val];
        } else {
          this._value = [val];
        }
      } else {
        if (!Array.isArray(val)) {
          this._value = [val];
        } else {
          this._value = [val[0]];
        }
      }

      this.writeValue();
    }
  }
  private _value: any;

  /**
   * Event that emits whenever the raw value of the select changes. This is here primarily
   * to facilitate the two-way binding for the `value` input.
   */
  @Output() readonly valueChange: EventEmitter<any> = new EventEmitter<any>();

  /**
   * A function to compare the option values with the selected Input value.
   * The first argument is a value from a select option component.
   * The second is a value from the value Input.
   * A boolean should be returned.
   *
   * Default compareFromId function compares the option list of objects against an input of selected IDs in an array.
   */
  @Input()
  get compareWith() {
    return this._compareWith;
  }
  set compareWith(fn: (o1: any, o2: any) => boolean) {
    if (typeof fn !== 'function') {
      throw Error('`compareWith` must be a function.');
    }
    this._compareWith = fn;
  }
  private _compareWith = compareFromId;

  /** Whether the user should be allowed to select multiple options. */
  @Input()
  get multiple(): boolean {
    return this._multiple;
  }
  set multiple(value: boolean) {
    if (this._selectionModel) {
      throw Error(
        'Cannot change `multiple` mode of select after initialization.'
      );
    }
    this._multiple = coerceBooleanProperty(value);
  }
  private _multiple = false;

  /**
   * Emits the selected option items as the user interacts with the select list.
   */
  @Output() onSelect: EventEmitter<SelectOptionComponent['item']> =
    new EventEmitter();

  @ContentChildren(SelectOptionComponent, { descendants: true })
  options: QueryList<SelectOptionComponent>;

  private _keyManager: ActiveDescendantKeyManager<SelectOptionComponent>;
  private readonly _destroy = new Subject<void>();
  /** Deals with the selection logic. */
  private _selectionModel: SelectionModel<SelectOptionComponent>;

  /** Combined stream of all of the child options' change events. */
  readonly optionSelectionChanges: Observable<OptionSelectionChange> = defer(
    () => {
      if (this.options) {
        return merge(...this.options.map((option) => option.onSelectChange));
      }

      return this._ngZone.onStable.asObservable().pipe(
        take(1),
        switchMap(() => this.optionSelectionChanges)
      );
    }
  );

  // child cr-option mouse enter listenter
  readonly childMouseEnterEvent: Observable<any> = defer(() => {
    if (this.options) {
      return merge(...this.options.map((item) => item.hasMouseEnter));
    }

    // set internal state of current child with pointer
    return this._ngZone.onStable.asObservable().pipe(
      take(1),
      switchMap(() => this.childMouseEnterEvent)
    );
  });

  constructor(
    public _ngZone: NgZone,
    public _ref: ChangeDetectorRef,
    public _elementRef: ElementRef,
    @Optional() @Inject(UI_SELECT_COMPONENT) private _parent: UiSelectComponent
  ) {}

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  ngAfterContentInit(): void {
    this._keyManager = new ActiveDescendantKeyManager<SelectOptionComponent>(
      this.options
    )
      .withVerticalOrientation()
      .withHomeAndEnd()
      .withWrap();

    this._selectionModel = new SelectionModel<SelectOptionComponent>(
      this.multiple,
      null,
      false
    );
    this.options.changes
      .pipe(startWith([null]), takeUntil(this._destroy))
      .subscribe(() => {
        this._resetList();
        Promise.resolve().then(() => {
          // Defer setting the value in order to avoid the "Expression
          // has changed after it was checked" errors from Angular.
          this._setSelectionByValue();
        });
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selected) {
      this._setSelectionByValue();
    }
  }

  get selectedOptions(): SelectOptionComponent | SelectOptionComponent[] {
    return this.multiple
      ? this._selectionModel.selected
      : this._selectionModel.selected[0];
  }

  writeValue(): void {
    if (this.options) {
      this._setSelectionByValue();
    }
  }

  onKeydown(event): void {
    const keyCode = event.keyCode;
    const isArrowKey = keyCode === DOWN_ARROW || keyCode === UP_ARROW;
    const manager = this._keyManager;

    if (keyCode === HOME || keyCode === END) {
      event.preventDefault();
      keyCode === HOME
        ? manager.setFirstItemActive()
        : manager.setLastItemActive();
    } else if (isArrowKey && event.altKey) {
      // Close the select on ALT + arrow key to match the native <select>
      event.preventDefault();
      this._parent.close();
    } else if ((keyCode === ENTER || keyCode === SPACE) && manager.activeItem) {
      event.preventDefault();
      manager.activeItem.selectViaInteraction();
    } else {
      const previouslyFocusedIndex = manager.activeItemIndex;

      manager.onKeydown(event);

      if (
        this._multiple &&
        isArrowKey &&
        event.shiftKey &&
        manager.activeItem &&
        manager.activeItemIndex !== previouslyFocusedIndex
      ) {
        manager.activeItem.selectViaInteraction();
      }
    }
  }

  onFocus(): void {
    const selectedOption = this.multiple
      ? this.selectedOptions[0]
      : this.selectedOptions;

    if (!!selectedOption) {
      this._keyManager.setActiveItem(selectedOption);
    } else {
      this._keyManager.setFirstItemActive();
    }
  }

  /** Focuses the select element. */
  focus(): void {
    this._elementRef.nativeElement.focus();
  }

  _resetList() {
    const changedOrDestroyed = merge(this.options.changes, this._destroy);

    // child cr-option mouse enter set focus on moused element
    this.childMouseEnterEvent
      .pipe(
        takeUntil(changedOrDestroyed),
        filter((event) => event.isUserInput)
      )
      .subscribe(
        (event: {
          isUserInput: boolean;
          item: any;
          source: SelectOptionComponent;
        }) => {
          this._keyManager.setActiveItem(event.source);
        }
      );

    // child cr-option on change handler emission
    this.optionSelectionChanges
      .pipe(
        takeUntil(changedOrDestroyed),
        filter((event) => event.isUserInput)
      )
      .subscribe((event: OptionSelectionChange) => {
        this._select(event.source);
      });
  }

  _select(option: SelectOptionComponent, isUserInput = true) {
    const wasSelected = this._selectionModel.isSelected(option);

    if (this.multiple) {
      this._selectionModel.toggle(option);
      this._keyManager.setActiveItem(option);

      // In case the user select the option with their mouse, we
      // want to restore focus back to the trigger, in order to
      // prevent the select keyboard controls from clashing with parent controls
      this.focus();
    } else {
      this._clearSelection(option.item == null ? undefined : option);
      this._selectionModel.select(option);
      this._parent.close();
    }

    if (wasSelected !== this._selectionModel.isSelected(option)) {
      this._emitChanges(isUserInput);
    }
  }

  _clearSelection(skip?: SelectOptionComponent): void {
    this._selectionModel.clear();
    this.options.forEach((option) => {
      if (option !== skip) {
        option.deselect();
      }
    });
  }

  /**
   * Emit the selected value(s)
   */
  _emitChanges(isUserInput) {
    let valueToEmit: any = null;
    if (this.multiple) {
      valueToEmit = (this.selectedOptions as SelectOptionComponent[]).map(
        (option) => option.item
      );
    } else {
      valueToEmit = this.selectedOptions
        ? (this.selectedOptions as SelectOptionComponent).item
        : null;
    }

    this._value = valueToEmit;

    if (isUserInput) {
      this.onSelect.emit(valueToEmit);
    }
    this.valueChange.emit(valueToEmit);
    this._ref.markForCheck();
  }

  _setSelectionByValue() {
    if (this.value && this._selectionModel) {
      if (this.multiple) {
        this._clearSelection();
        this.value.forEach((selection: any) => this._selectValue(selection));
      } else {
        this._clearSelection();

        const correspondingOption = this._selectValue(
          this.value[0] || this.value
        );

        if (correspondingOption) {
          this._keyManager.setActiveItem(correspondingOption);
        } else {
          this._emitChanges(false);
        }
      }
    }
  }

  _selectValue(value: any): SelectOptionComponent | undefined {
    const correspondingOption = this.options.find(
      (option) => option.item != null && this.compareWith(option.item, value)
    );

    if (correspondingOption) {
      correspondingOption.select();
      this._select(correspondingOption, false);
    }

    return correspondingOption;
  }
}
