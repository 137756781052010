/**
 * @cabooseInclude
 */

import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  SimpleChange,
  ViewChild,
} from '@angular/core';

import { Subject } from 'rxjs';

import { appAnimations } from '../../constants/app-animations';

import { TooltipBaseComponent } from '../tooltip-overlaid/tooltip-base/tooltip-base.component';

/** Displays a HTML capable tooltip that's not interactive. */
@Component({
  selector: 'ui-tooltip-overlaid',
  animations: appAnimations,
  templateUrl: './tooltip-overlaid.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'tooltip-overlay-wrapper',
    '[class.tooltip-underline]': 'this.underline',
    '[class.tooltip-defaultCursor]': 'this.defaultCursor',
  },
})
export class TooltipOverlaidComponent implements OnDestroy {
  /** Which direction the tooltip appears from, top, right, bottom, or left, top-left, top-right, bottom-left, or bottom-right */
  @Input() public position = 'top';
  /**
   * The size of the tooltip. Large tooltips wrap onto multiple lines after about `300px` of content,but small tooltips
   * try to stay on a single line (and potentially become thousands of pixels wide). If you're expecting more than a
   * couple words of content, then use the large version.
   */
  @Input() public large = false;
  /** Whether the tooltip has a light or dark background */
  @Input() public light = true;
  /** Whether the target html element is underlined */
  @Input() public underline = false;
  /** Whether to apply `display: none` to the tooltip content */
  @Input() public forceHide = false;
  /** Whether to use the cursor: initial or cursor: pointer */
  @Input() public defaultCursor = false;
  /** Whether to apply the uiEdgeSafeTooltip directive */
  @Input() public useDynamicPositioning = false;
  /** Output when tooltip opens */
  @Output() public onOpen: EventEmitter<any>;
  /** Output when tooltip closes */
  @Output() public onClose: EventEmitter<any>;
  @ViewChild('tooltipBase') public tooltipBase: TooltipBaseComponent;

  private readonly destroy = new Subject<void>();

  constructor(private elementRef: ElementRef) {}

  @HostListener('mouseenter') public show(): void {
    this.initialize();
    this.tooltipBase.show();
  }

  @HostListener('mouseleave') public hide(): void {
    this.tooltipBase.hide();
  }

  public ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    // if tooltip is added to a button that becomes disabled
    if (!changes.disabled?.previousValue && changes.disabled?.currentValue) {
      this.hide();
    }
  }

  public ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
    this.hide();
  }

  private initialize() {
    if (!this.tooltipBase.overlayRef) {
      this.tooltipBase.targetRef = this.elementRef;
      this.onOpen = this.tooltipBase.onOpen;
      this.onClose = this.tooltipBase.onClose;
    }
  }
}
