import { computed, Inject, Injectable, Optional, signal } from '@angular/core';

declare global {
  interface Window {
    isMobile?: boolean;
    isSalesforceCanvas?: boolean;
    isRingCentralIframe?: boolean;
  }
}

@Injectable({
  providedIn: 'root',
})
export class WindowRefService {
  private iframeType = signal<string>(null);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  inSemrushIframe = computed(() => this.iframeType() === 'semrush');
  // eslint-disable-next-line @typescript-eslint/member-ordering
  inIframe = computed(() => this.iframeType() != null);

  constructor(@Optional() @Inject('window') private window?: Window) {
    // eslint-disable-next-line local-rules/no-window-location
    if (location?.search?.includes('is_semrush_iframe')) {
      this.iframeType.set('semrush');
    }
  }

  public nativeWindow(): any {
    /*
      used in various parts of our angular apps to
      dermine what to do for mobile users

      taken from: http://stackoverflow.com/questions/3514784/what-is-the-best-way-to-detect-a-handheld-device-in-jquery
    */
    this._nativeWindow.isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        this._nativeNavigator.userAgent
      );

    return this._nativeWindow;
  }

  public get _nativeWindow() {
    return this.window || (window as any);
  }

  public get _nativeNavigator() {
    return navigator;
  }

  public get isSalesforceCanvas(): boolean {
    return (this.nativeWindow() as Window).isSalesforceCanvas;
  }

  public get isRingCentralIframe(): boolean {
    return (this.nativeWindow() as Window).isRingCentralIframe;
  }

  public postTopMessage(message: any, target: string): void {
    this.nativeWindow().top?.postMessage(message, target);
  }
}
