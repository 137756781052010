import { Injectable, signal } from '@angular/core';
import { Observable, map } from 'rxjs';

import { HeaderTokenAuthTokenResponse } from '../../interfaces/header-token-auth-token-response';
import { SemrushIframeService } from '../semrush-iframe/semrush-iframe.service';
import { WindowRefService } from '@callrail/looky/util';
import { tap } from 'rxjs/operators';

type IdentityProvider = 'semrush';

@Injectable({
  providedIn: 'root',
})
export class HeaderTokenAuthService {
  public jwt: string;
  private idpSig = signal<IdentityProvider>(null);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public idp = this.idpSig.asReadonly();

  constructor(
    private semrushIframesService: SemrushIframeService,
    windowRef: WindowRefService
  ) {
    if (windowRef.inSemrushIframe()) {
      this.idpSig.set('semrush');
      this.semrushIframesService.semrushToken$.subscribe({
        next: (token) => (this.jwt = token),
      });
    }
  }

  public authenticate(): Observable<HeaderTokenAuthTokenResponse> {
    switch (this.idp()) {
      case 'semrush':
        return this.semrushIframesService.refreshToken().pipe(
          tap((jwt: string) => (this.jwt = jwt)),
          map((jwt: string) => ({ jwt }))
        );
    }
  }

  public callTrkAuthorizationHeader(): string {
    switch (this.idp()) {
      case 'semrush':
        return 'SemrushTokenAuth';
    }
  }
}
