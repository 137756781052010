<label
  [class.large]="size === 'large'"
  [class.align-top]="align === 'top'">
  <input
    type="checkbox"
    [checked]="isChecked"
    [attr.disabled]="isDisabled"
    [attr.indeterminate]="isIndeterminate ? 'true' : null" />
  <span class="checkbox">
    <i
      [class.!tw-opacity-0]="isIndeterminate"
      class="icon-check"></i>

    <div
      *ngIf="isIndeterminate"
      class="tw-absolute tw-top-1/2 -tw-mt-[0.75px] tw-h-[1.5px] tw-w-full tw-bg-white"></div>
  </span>
  <span
    #labelContent
    [class.u-padding-left-10]="labelContent.innerHTML.length">
    <ng-content></ng-content>
  </span>
</label>
